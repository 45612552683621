import React from "react";

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  return (
    <div className="d-flex align-items-center">
      <span className="me-2" style={{fontSize: "14px", fontWeight: "bold"}}>
        Page {currentPage} - {totalPages} sur {totalPages}
      </span>
      <button
        className="btn btn-link"
        style={{fontSize: "14px", color: "#003366"}}
        onClick={() => handlePageChange("prev")}
        disabled={currentPage === 1}
      >
        &lt;
      </button>
      <button
        className="btn btn-link"
        style={{fontSize: "14px", color: "#003366"}}
        onClick={() => handlePageChange("next")}
        disabled={currentPage === totalPages}
      >
        &gt;
      </button>
    </div>
  );
};

export default Pagination;
