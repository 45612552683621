import React from "react";
import "../styles/Sidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);

  // Définition des éléments du menu
  const menuItems = [
    { path: "/", icon: "bi-house-door", label: "Accueil" },
    { path: "/networking", icon: "bi-people-fill", label: "Réseautage" },
    // { path: "/networking/friend", icon: "bi-people", label: "Mes contacts", authRequired: true },
    { path: "/dashboard", icon: "bi-person-circle", label: "Espace client", authRequired: true },
    { path: "/learning", icon: "bi-camera-video", label: "Formations" },
    { path: "/pages", icon: "bi-flag", label: "Pages" },
    { path: "/event", icon: "bi-calendar-event", label: "Événements" },
    { path: "/actualites", icon: "bi-rss", label: "Fils d'actualité" },
    { path: "/historique", icon: "bi-clock-history", label: "Historique" },
  ];

  // Fonction de navigation
  const handleNavigation = (e, path, authRequired) => {
    e.preventDefault();
    navigate(authRequired && !isAuthenticated ? "/login" : path);
  };

  return (
    <aside className="sidebar d-none d-sm-block">
      <nav>
        <ul style={{ paddingLeft: 0, listStyle: "none" }}>
          {menuItems.map(({ path, icon, label, authRequired }) => (
            <li key={path}
              style={{
                borderBottom: "1px solid #eee",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              <Link
                to={authRequired ? "#" : path}
                onClick={(e) => authRequired && handleNavigation(e, path, authRequired)}
                className={location.pathname === path ? "active" : ""}
                style={{
                  
                  fontWeight: "bold",
                  
                  transition: "color 0.3s ease",
                }}
              >
                <i className={`bi ${icon}`}></i> {label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
