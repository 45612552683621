import React from "react";
import { AGRO_DOMAINS } from "../utils/Constants";

const Filters = ({
  selectedDateRange,
  handleDateRangeChange,
  selectedDomain,
  handleDomainChange,
}) => {
  return (
    <div className="d-flex flex-column flex-md-row w-100">
      {/* Filtre par date */}
      <select
        className="form-select me-md-2 mb-2 mb-md-0"
        value={selectedDateRange}
        onChange={handleDateRangeChange}
        style={{ minWidth: "168px" }}
      >
        <option value="Toutes les dates">Toutes les dates</option>
        <option value="today">Aujourd'hui</option>
        <option value="Dernier 30 jours">30 derniers jours</option>
        <option value="Dernier 7 jours">7 derniers jours</option>
        <option value="Cette année">Cette année</option>
      </select>

      {/* Filtre par domaine */}
      <select
        className="form-select"
        value={selectedDomain}
        onChange={handleDomainChange}
        style={{ minWidth: "218px" }}
      >
        {AGRO_DOMAINS.map((domain) => (
          <option key={domain.label} value={domain.label}>
            {domain.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Filters;
