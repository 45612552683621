import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/Constants";

// ✅ Fonction pour récupérer les headers avec le token (utilisation de sessionStorage)
const getAuthHeaders = () => ({
  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
});

// ✅ Thunk pour récupérer le profil utilisateur
export const fetchUserProfile = createAsyncThunk(
  "userProfile/fetchUserProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/profile`, {
        headers: getAuthHeaders(),
      });

      console.log("🎯 API Response Data (fetchUserProfile):", response.data);
      return response.data;
    } catch (error) {
      console.error("❌ API Fetch Error:", error.response?.data || "Erreur serveur");

      return rejectWithValue(error.response?.data?.message || "Erreur serveur");
    }
  }
);

// ✅ Thunk pour mettre à jour le profil utilisateur
export const updateUserProfile = createAsyncThunk(
  "userProfile/updateUserProfile",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/profile/update`, formData, {
        headers: {
          ...getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("✅ API Response Data (updateUserProfile):", response.data);
      return response.data;
    } catch (error) {
      console.error("❌ API Update Error:", error.response?.data || "Erreur serveur");

      return rejectWithValue(error.response?.data?.message || "Erreur serveur");
    }
  }
);

// ✅ Création du slice Redux
const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    profile: null,
    user: null,
    logo_url: null,
    loading: false,
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      // ✅ Récupération du profil utilisateur
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        console.log("🔥 Redux - Data received in fetchUserProfile.fulfilled:", action.payload);

        state.loading = false;
        state.profile = action.payload.profile || null;
        state.user = action.payload.user || {}; 
        state.logo_url = action.payload.logo_url || null;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        console.error("❌ Redux Fetch Error:", action.payload);
        state.loading = false;
        state.error = action.payload;
      })

      // ✅ Mise à jour du profil utilisateur
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        console.log("🔥 Redux - Data received in updateUserProfile.fulfilled:", action.payload);

        state.loading = false;
        state.profile = action.payload.profile || state.profile;
        state.user = action.payload.user || state.user;
        state.logo_url = action.payload.logo_url || state.logo_url;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        console.error("❌ Redux Update Error:", action.payload);
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userProfileSlice.reducer;
