import axios from "axios";

// font-family-sans-serif: Sora,system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
//     --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace

// export const API_BASE_URL = "http://127.0.0.1:8000/api";
export const API_BASE_URL = "https://api.espacenegoce.com/api";

// Endpoints disponibles
export const ENDPOINTS = {
  ADS: "/annonces",          
  LOGIN: "/auth/login",      
  REGISTER: "/auth/register" 
};

// Création de l'instance Axios avec configuration par défaut
export const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000, 
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// api.get('/test-login')
//   .then(response => console.log(response.data))
//   .catch(error => console.error("Erreur API:", error));

// Intercepteur de requêtes
api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token"); 
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercepteur de réponses
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Si le token a expiré et qu'on n'a pas encore essayé de le renouveler
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; 
      try {
        const refreshToken = sessionStorage.getItem("refresh_token"); // ⬅️ Remplacement ici aussi
        if (!refreshToken) {
          throw new Error("Pas de refresh token disponible.");
        }

        // Appel au backend pour renouveler le token
        const response = await axios.post(`${API_BASE_URL}/auth/refresh`, {
          refresh_token: refreshToken,
        });

        const newToken = response.data.token;

        // Mise à jour du token dans sessionStorage
        sessionStorage.setItem("token", newToken);

        // Mise à jour du header Authorization pour la requête originale
        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        // Refaire la requête originale avec le nouveau token
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Erreur lors du renouvellement du token :", refreshError);
        // Suppression des tokens et redirection vers la connexion
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
