import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { API_BASE_URL } from "../../../utils/api";

// ✅ Thunk pour récupérer les informations officielles
export const fetchUserOfficialInfo = createAsyncThunk(
  "userOfficialInfo/fetchUserOfficialInfo",
  async (_, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.get(`${API_BASE_URL}/official-info`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("✅ Données récupérées :", response.data);
      return response.data;
    } catch (error) {
      console.error("❌ Erreur lors du chargement des données :", error);
      return rejectWithValue(error.response?.data || "Erreur serveur");
    }
  }
);

// ✅ Thunk pour mettre à jour les informations officielles
export const updateUserOfficialInfo = createAsyncThunk(
  "userOfficialInfo/updateUserOfficialInfo",
  async (formData, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(`${API_BASE_URL}/official-info/update`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("✅ Mise à jour réussie :", response.data);
      return response.data;
    } catch (error) {
      console.error("❌ Erreur lors de la mise à jour :", error);
      return rejectWithValue(error.response?.data || "Erreur serveur");
    }
  }
);

// ✅ Création du slice Redux
const userOfficialInfoSlice = createSlice({
  name: "userOfficialInfo",
  initialState: {
    info: null,
    loading: false,
    error: null,
    successMessage: null,
  },
  reducers: {
    clearSuccessMessage: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // ✅ Récupération des infos
      .addCase(fetchUserOfficialInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserOfficialInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(fetchUserOfficialInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // ✅ Mise à jour des infos
      .addCase(updateUserOfficialInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(updateUserOfficialInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
        state.successMessage = "Les informations ont été mises à jour avec succès !";
      })
      .addCase(updateUserOfficialInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearSuccessMessage } = userOfficialInfoSlice.actions;
export default userOfficialInfoSlice.reducer;
