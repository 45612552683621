import React from "react";
import "../../styles/ads.css";
import { Link } from "react-router-dom";
import { formatDistanceToNow, format } from "date-fns";
import { fr } from "date-fns/locale";

const ListAd = ({ imageUrl, slug, title, author, need_type, food_industry_field, description, createdAt, quantity, price, viewMode }) => {
  const relativeDate = createdAt
    ? formatDistanceToNow(new Date(createdAt), { locale: fr, addSuffix: true })
    : "Date inconnue";

  const formattedDate = createdAt ? format(new Date(createdAt), "dd/MM/yyyy") : "Date inconnue";

  return viewMode === "list" ? (
    // Mode Liste
    <div
    className="card"
    style={{
      maxWidth: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      borderRadius: "10px",
      overflow: "hidden",
      border: "1px solid #ddd",
      margin: "10px 0",
      padding: "0",
    }}
  >
    {/* Image de l'annonce */}
    <div style={{ flex: "0 0 200px" }}>
      <img src={imageUrl} alt={title} className="responsive-image" />
    </div>

    {/* Informations de l'annonce */}
    <div
      className="card-body"
      style={{
        padding: "15px",
        flex: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <h5 className="card-title" style={{ fontWeight: "bold" }}>
        {title}
      </h5>
      <div className="row">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center w-100 py-2">
          <strong className={`button-${need_type === "Achat" ? "buy" : "sell"} mb-2 mb-md-0`}>
            {need_type}
          </strong>
          <strong>Rubrique : {food_industry_field}</strong>
        </div>
      </div>

      <p
        className="card-text"
        style={{ color: "#666", marginBottom: "10px", fontSize: "14px" }}
      >
        Postée par : <strong>{author}</strong> <span>{relativeDate}</span>
      </p>
      <div
        className="card-text"
        style={{
          color: "#333",
          fontSize: "14px",
          lineHeight: "1.5",
          maxHeight: "4.5em",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
        }}
      >
        {description}
      </div>
      {/* Lien Lire la suite */}
      <div className="d-flex justify-content-end align-content-end mt-2">
        <Link to={`/ads/${slug}`} style={{ color: "#036", fontWeight: "bold" }}>
          Voir les détails...
        </Link>
      </div>
    </div>
  </div>
  ) : (
    // Mode Carte détaillée
    <div className="col-12 col-lg-12">
            <div className="card" style={{ padding: "0px", border: "1px solid #ddd", borderRadius: "10px", marginBottom: "10px" }}>
              {/* Ajout d'un wrapper avec padding pour le contenu (hors image) */}
              <div style={{ padding: "10px 15px 0px 10px" }}>
                <div className="d-flex justify-content-between align-items-center ">
                  <div className="card-body p-0"> {/* Suppression du padding par défaut de card-body */}
                    <h4>{title}</h4>
                    <p className="text-muted">
                      Posté le : Posté le : {formattedDate}
                    </p>
                  </div>
                  <div>
                    <span className={`button-${need_type === "Achat" ? "buy" : "sell"} mb-2 mb-md-0`}>
                      {need_type}
                    </span>
                  </div>
                </div>
              </div>

              {/* Image sans padding */}
             
              <img src={imageUrl} alt={title} className="responsive-image" 
              style={{ 
                maxHeight: "400px",
                objectFit: "cover",
                width: "100%",
              }} />


              {/* Ajout de padding pour la description et les détails */}
              <div style={{ padding: "10px 18px 0px 20px" }}>
                <div className="mt-2">
                  <p>{description || "Aucune description disponible."}</p>
                  <hr />
                </div>
                <div className="mt-2 row">
                  <div className="col-6">
                    <p>
                      <strong>Quantité :</strong> {quantity || "Non spécifiée"}
                    </p>
                    <p>
                      <strong>Prix :</strong> {price || "Non spécifié"}
                    </p>
                  </div>
                  <div className="col-12">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
  );
};

export default ListAd;
