import React, { useEffect, useState } from "react";
import "../../styles/Formation.css"; // Assure-toi d'avoir ce fichier pour le style

const videos = [
  {
    url: "https://www.youtube.com/embed/LJa1EiWBJVw",
    title: "Ce produit Juteux va nous rapporter des milliards de FCFA en Dropshipping des matières Premières",
    description: "Description de la vidéo 1. Cette vidéo traite de ...",
  },
  {
    url: "https://www.youtube.com/embed/8zLyrSCUZHo",
    title: "Le NEGOCE INTERNATIONAL DES PRODUITS AGRICOLES: Comment Bien Démarrer ce Business en Afrique",
    description: "Description de la vidéo 2. Cette vidéo traite de ...",
  },
  {
    url: "https://www.youtube.com/embed/0dg6o1E90PI",
    title: "Vidéo 1: Négoce des denrées alimentaires en Afrique 🤑 : voici comment trouver des clients à l'international",
    description: "Description de la vidéo 3. Cette vidéo traite de ...",
  },
  {
    url: "https://www.youtube.com/embed/IzErn7OE5iE",
    title: "Vidéo 2: 🚀 Devenez un Maître du Négoce de matière premières en 4 Étapes Essentielles ! 🌍",
    description: "Description de la vidéo 4. Cette vidéo montre ...",
  },
  {
    url: "https://www.youtube.com/embed/SdAny28jRVQ",
    title: "Vidéo 3: Découvrez le business du négoce en Afrique 💼 : Fonctionnement, opportunités et stratégies ! 💰",
    description: "Description de la vidéo 5. Regardez cette vidéo pour ...",
  },
  {
    url: "https://www.youtube.com/embed/ME77tLJsGQI",
    title: "Vidéo 4: Transformation par le négoce : Témoignages inspirants et opportunités lucratives! 🌟💼",
    description: "Description de la vidéo 6. Apprenez comment ...",
  },
];

const FormationIndex = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container mt-4 formation-container"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito",
        fontWeight: "500",
      }}
    >
      <h3 className="text-center mb-4">📚 Les meilleurs formateurs francophone sur le Négoce</h3>

      <div className="row">
        {videos.map((video, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-4">
            <div className="card video-card shadow-sm" style={{ margin: "0 auto", maxHeight: "378px;", padding: "0px" }}>
              <iframe
                width="100%"
                height="200"
                src={video.url}
                title={video.title}
                frameBorder="0"
                allowFullScreen
              ></iframe>
              <div className="card-body" style={{ overflow: "hidden" }}>
                <h5 className="video-title">{video.title}</h5>
                <p className="video-description">{video.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormationIndex;
