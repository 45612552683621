import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile, updateUserProfile } from "../../redux/slices/userSlices/userProfileSlice";
import { COUNTRIES, ACTORS, AGRO_DOMAINS } from "../../utils/Constants";
import UserMenuCard from "./UserMenuCard";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { profile, user, logo_url, loading, error } = useSelector((state) => state.userProfile);
  console.log("🔥 Redux State in Component - User:", user);
  console.log("🔥 Redux State in Component - Profile:", profile);
  const [formData, setFormData] = useState({
    lastname: "",
    firstname: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    postal_code: "",
    country: "",
    registration_number: "",
    vat_number: "",
    actor: "",
    domain: "",
    employees: "",
    hasWebsite: false,
    logo: null,
    businessDetails: "",
    capacityDetails: "",
  });

  const [successMessage, setSuccessMessage] = useState(""); 


  // ✅ Charger les données du profil utilisateur au montage
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  // ✅ Mettre à jour le formulaire avec les données existantes
  useEffect(() => {
  
    if (profile && user && Object.keys(user).length > 0) { // ✅ Vérifie si user n'est pas vide
      setTimeout(() => {
        setFormData((prev) => ({
          ...prev,
          lastname: user?.lastname || "",
          firstname: user?.firstname || "",
          email: user?.email || "",
          phone: user?.phone || "",
          address: profile?.address || "",
          city: profile?.city || "",
          postal_code: profile?.postal_code || "",
          country: profile?.country || "",
          registration_number: profile?.registration_number || "",
          vat_number: profile?.vat_number || "",
          actor: profile?.actor || "",
          domain: profile?.domain || "",
          employees: profile?.employees?.toString() || "",
          hasWebsite: profile?.has_website || false,
          businessDetails: profile?.business_details || "",
          capacityDetails: profile?.capacity_details || "",
        }));
        console.log("🔄 Forced FormData update:", formData);
      }, 500);
    }
  }, [profile, user]);
  

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, logo: files[0] });
    } else if (type === "radio") {
      setFormData({ ...formData, hasWebsite: value === "true" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null) {
        data.append(key, value);
      }
    });
  
    try {
      const result = await dispatch(updateUserProfile(data));
  
      // ✅ Afficher un message de succès si la mise à jour réussit
      if (updateUserProfile.fulfilled.match(result)) {
        setSuccessMessage("Votre profil a été mis à jour avec succès !");
        setTimeout(() => {
          setSuccessMessage(""); // ✅ Masquer l'alerte après 3 secondes
        }, 10000);
        
      }
    } catch (error) {
      console.error("❌ Erreur lors de la mise à jour du profil :", error);
    }
  };
  

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    


  return (
    <div className="container mt-4"
    style={{
      maxWidth: "100%",
      marginLeft: isMobile ? "0px" : "210px",
      fontFamily: "Nunito", fontWeight: "500", fontStyle: "normal",
      padding: "30px"
    }}>

<style>
      {`
        label {
          font-size: 16px;
          font-weight: bold;
          color: #003366;
        }
          input, textarea, select {
          color: #003366;
          font-size: 16px;
        }
      `}
    </style>
       
      <div className="row">

        <div className="col-lg-9">
          <div className="card p-4" style={{ maxWidth: "800px" }}>
            <h3 className="mt-4 mb-3" style={{ color: "#003366" }}>
             Mettez à jour votre profil en remplissant ces informations.
            </h3>
            <p><strong>N'oubliez pas d'enregistrer le formulaire </strong>une fois terminé. Nous prenons en compte votre demande et mettons à jour automatiquement votre profil.</p>
            <div className="alert alert-warning">
              Ces indications nous servent à mieux connaître votre entreprise et ainsi mieux vous présenter à la communauté.
              Retenez aussi que les profiles correctement remplis sont mis en valeur par notre plateforme.
            </div>

            {loading && <p>Chargement...</p>}
             {/* ✅ Alerte Bootstrap */}
            {successMessage && (
              <div className="alert alert-success alert-dismissible fade show" role="alert">
                {successMessage}
                <button type="button" className="btn-close" onClick={() => setSuccessMessage("")}></button>
              </div>
            )}

            {/* ✅ Affichage des erreurs */}
            {error && <div className="alert alert-danger">{error}</div>}

            <form onSubmit={handleSubmit}>
              <div className="row">
                {/* LOGO */}
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <strong className="me-3">Votre logo</strong>
                    {logo_url && <img src={logo_url} alt="Logo" className="mb-2" style={{ width: "70px", height: "70px", borderRadius: "50px" }} />}
                </div>


                <div className="col-md-6 mb-2 mt-2">
                  <label className="form-label">Modifier le logo</label>

                  <input type="file" name="logo" className="form-control " accept="image/png, image/jpeg" onChange={handleChange} />
                </div>


                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Nom <span style={{color: "red"}}>*</span></label>
                  <input type="text" name="lastname" className="form-control" required value={formData.lastname} onChange={handleChange} />
                </div>
                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Prénom <span style={{color: "red"}}>*</span></label>
                  <input type="text" name="firstname" className="form-control" required value={formData.firstname} onChange={handleChange} />
                </div>
                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Email <span style={{color: "red"}}>*</span></label>
                  <input type="email" name="email" className="form-control" required value={formData.email} onChange={handleChange} />
                </div>
                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Téléphone <span style={{color: "red"}}>*</span></label>
                  <input type="text" name="phone" className="form-control" required value={formData.phone} onChange={handleChange} />
                </div>


                {/* CHAMPS TEXTE */}
                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Adresse <span style={{color: "red"}}>*</span></label>
                  <input type="text" name="address" className="form-control" required value={formData.address} onChange={handleChange} />
                </div>

                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Ville <span style={{color: "red"}}>*</span></label>
                  <input type="text" name="city" className="form-control" required value={formData.city} onChange={handleChange} />
                </div>

                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Code Postal <span style={{color: "red"}}>*</span></label>
                  <input type="text" name="postal_code" className="form-control" required value={formData.postal_code} onChange={handleChange} />
                </div>

                {/* PAYS */}
                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Pays <span style={{color: "red"}}>*</span></label>
                  <select name="country" className="form-control" required value={formData.country} onChange={handleChange}>
                    <option value="">Sélectionnez un pays</option>
                    {COUNTRIES.map((country) => (
                      <option key={country.code} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* DOMAINE */}
                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Domaine d'activité <span style={{color: "red"}}>*</span></label>
                  <select name="domain" className="form-control" required value={formData.domain} onChange={handleChange}>
                    {AGRO_DOMAINS.map((domain) => (
                      <option key={domain.value} value={domain.value}>{domain.label}</option>
                    ))}
                  </select>
                </div>

                


                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Numéro d'immatriculation (RCS ou SIREN) <span style={{color: "red"}}>*</span></label>
                  <input type="text" name="registration_number" className="form-control" value={formData.registration_number} required onChange={handleChange} />
                </div>

                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">N° de TVA (si vous êtes en Europe) <span style={{color: "red"}}>*</span></label>
                  <input type="text" name="vat_number" className="form-control" value={formData.vat_number} onChange={handleChange} />
                </div>

                {/* ACTEUR */}
                <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Vous êtes <span style={{color: "red"}}>*</span></label>
                  <select name="actor" className="form-control" required value={formData.actor} onChange={handleChange}>
                    {ACTORS.map((actor) => (
                      <option key={actor.value} value={actor.value}>{actor.label}</option>
                    ))}
                  </select>
                </div>

                
              
               {/* EMPLOYÉS */}
               <div className="col-md-6  mb-2 mt-2">
                  <label className="form-label">Effectifs <span style={{color: "red"}}>*</span></label>
                  <select name="employees" className="form-control" required value={formData.employees} onChange={handleChange}>
                    <option value="">Sélectionner</option>
                    <option value="1-10">1-10</option>
                    <option value="11-50">11-50</option>
                    <option value="51-100">51-100</option>
                    <option value="100+">100+</option>
                  </select>
                </div>

               
                {/* Avez-vous un site internet ? */}
                <div className="col-md-12  mb-2 mt-2">
                  <label className="form-label">Avez-vous un site internet ? <span style={{color: "red"}}>*</span></label>
                  <div>
                    <input type="radio" name="hasWebsite" value="true" checked={formData.hasWebsite === true} onChange={handleChange} /> Oui
                    <input type="radio" name="hasWebsite" value="false" className="ms-3" checked={formData.hasWebsite === false} onChange={handleChange} /> Non
                  </div>
                </div>

                {/* PRÉCISIONS SUR L'ACTIVITÉ */}
                <div className="col-md-12  mb-2 mt-2">
                  <label className="form-label">Précisions sur votre activité <span style={{color: "red"}}>*</span></label>
                  <textarea name="businessDetails" className="form-control" rows="3" required value={formData.businessDetails} onChange={handleChange}></textarea>
                </div>

                {/* INDICATIONS SUR LES CAPACITÉS */}
                <div className="col-md-12  mb-2 mt-2">
                  <label className="form-label">Indications sur vos capacités <span style={{color: "red"}}>*</span></label>
                  <textarea name="capacityDetails" className="form-control" rows="3" required value={formData.capacityDetails} onChange={handleChange}></textarea>
                </div>

                {/* BOUTON ENREGISTRER */}
                <div className="col-md-12  mb-2 mt-2">
                  <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                    {loading ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2"></span>
                        Mise à jour...
                      </>
                    ) : (
                      "Enregistrer"
                    )}
                  </button>
                </div>

              </div>
            </form>
          </div>
        </div>

         {/* Informations requises */}
         <div className="col-lg-3">
             <UserMenuCard />
        </div>

      </div>
    </div>
  );
};

export default UserProfile;
