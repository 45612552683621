import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import UserMenuCard from "./UserMenuCard"; 

const UserChangeEmail = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [email, setEmail] = useState(""); 
  const navigate = useNavigate(); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fonction pour valider le changement d'email
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim() && email.includes("@")) {
      alert(`Votre email a été mis à jour : ${email}`);
      setEmail(""); // Réinitialisation du champ après validation
    } else {
      alert("Veuillez entrer une adresse email valide.");
    }
  };

  return (
    <div className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito",
        fontWeight: "500",
        fontStyle: "normal",
        padding: "30px",
      }}
    >
      <div className="row">
        
        {/* Colonne principale (col-9) avec une Card */}
        <div className="col-lg-9 col-12 d-flex justify-content-center align-items-center text-center" style={{ height: "80vh" }}>
          <div className="card p-4 shadow" style={{ width: "100%", maxWidth: "600px" }}> {/* ✅ Ajout d'une Card */}
            <div className="card-body">
              <h3 className="card-title mb-3">Changer votre adresse e-mail</h3>
              <p className="card-text text-muted">
                Saisissez votre **nouvelle adresse e-mail** ci-dessous et cliquez sur "Mettre à jour" pour valider la modification.
              </p>

              {/* Formulaire de changement d'email */}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Nouvelle adresse e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ fontSize: "16px", color: "#003366" }}
                    required
                  />
                </div>

                <button type="submit" className="btn btn-primary mt-3 w-100">
                  Mettre à jour
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Colonne UserMenuCard (col-3) */}
        <div className="col-lg-3 col-12 mb-3">
          <UserMenuCard />
        </div>

      </div>
    </div>
  );
};

export default UserChangeEmail;
