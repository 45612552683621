import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserOfficialInfo, updateUserOfficialInfo } from "../../redux/slices/userSlices/userOfficialInfoSlice";
import UserMenuCard from "./UserMenuCard";

const UserOfficialInfo = () => {
  const dispatch = useDispatch();
  const { info, loading, successMessage, error } = useSelector((state) => state.userOfficialInfo);

  const [formData, setFormData] = useState({
    trade_register_number: "",
    issuing_organization: "",
    main_bank: "",
    export_license_number: "",
    import_license_number: "",
    company_status_comment: "",
    has_business_identification_number: false,
    agrees_to_provide_official_docs: false,
    agrees_to_provide_partners: false,
    can_provide_BCL_BG: false,
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // ✅ Charger les informations officielles depuis Redux
  useEffect(() => {
    dispatch(fetchUserOfficialInfo());
  }, [dispatch]);

  // ✅ Mettre à jour les champs si `info` existe
  useEffect(() => {
    if (info) {
      setFormData({
        trade_register_number: info.trade_register_number || "",
        issuing_organization: info.issuing_organization || "",
        main_bank: info.main_bank || "",
        export_license_number: info.export_license_number || "",
        import_license_number: info.import_license_number || "",
        company_status_comment: info.company_status_comment || "",
        has_business_identification_number: info.has_business_identification_number || false,
        agrees_to_provide_official_docs: info.agrees_to_provide_official_docs || false,
        agrees_to_provide_partners: info.agrees_to_provide_partners || false,
        can_provide_BCL_BG: info.can_provide_BCL_BG || false,
      });
    }
  }, [info]);

  // ✅ Gérer les changements des champs
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "radio" ? value === "true" : value,
    }));
  };

  // ✅ Gérer l'envoi du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await dispatch(updateUserOfficialInfo(formData));

    // ✅ Afficher le message de succès si la mise à jour est réussie
    if (updateUserOfficialInfo.fulfilled.match(result)) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        window.location.reload(); // ✅ Recharge la page après 3 secondes
      }, 3000);
    }
  };

  return (
    <div className="container mt-4"
         style={{
           maxWidth: "100%",
           marginLeft: window.innerWidth < 768 ? "0px" : "210px",
           fontFamily: "Nunito", fontWeight: "500", fontStyle: "normal",
           padding: "30px"
         }}>

      <style>
      {`
        label {
          font-size: 16px;
          font-weight: bold;
          color: #003366;
        }
          input, textarea, select {
          color: #003366;
          font-size: 16px;
        }
      `}
    </style>
     <div className="row">
         <div className="col-lg-9">
              <div className="card p-4" style={{ maxWidth: "850px" }}>

              <h3 className="mt-4 mb-3 text-center" style={{ color: "#003366" }}>              
                Les informations officielles de votre entreprise
              </h3>

            {/* ✅ Affichage du message de succès */}
            {showSuccessMessage && <div className="alert alert-success">Les informations ont été mises à jour avec succès !</div>}
            {error && <div className="alert alert-danger">{error}</div>}

            <form onSubmit={handleSubmit}>
              {/* Section: Remplir les champs */}
              <div className="border p-3 mt-3">
                <h5 className="font-weight-bold" style={{color: "red"}}>Veuillez remplir les champs suivants</h5>

                <div className="row">
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="form-label" >Numéro du registre de commerce: <strong style={{color: "red"}}>*</strong> </label>
                    <input type="text" name="trade_register_number" className="form-control" required value={formData.trade_register_number} onChange={handleChange} />
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="form-label">Délivré par: <strong style={{color: "red"}}>*</strong></label>
                    <input type="text" name="issuing_organization" className="form-control" required placeholder="Organisme + ville + pays" value={formData.issuing_organization} onChange={handleChange} />
                  </div>
                  <div className="col-md-12 mt-2 mb-2">
                    <label className="form-label">Quel est le nom de votre banque principale (facultatif):</label>
                    <input type="text" name="main_bank" className="form-control" value={formData.main_bank} onChange={handleChange} />
                  </div>
                  <div className="col-md-6 mt-2 mb-2">
                    <label className="form-label">Quel est votre numéro de licence d'exportation: <strong style={{color: "red"}}>*</strong></label>
                    <input type="text" name="export_license_number" className="form-control" required value={formData.export_license_number} onChange={handleChange} />
                  </div>
                  <div className="col-md-6 mt-2 mb-2">
                    <label className="form-label">Quel est votre numéro de licence d'importation: <strong style={{color: "red"}}>*</strong></label>
                    <input type="text" name="import_license_number" className="form-control" required value={formData.import_license_number} onChange={handleChange} />
                  </div>
                  <div className="col-md-12 mt-2 mb-2">
                    <label className="form-label">Commentaire sur la situation de votre entreprise (Facultatif):</label>
                    <textarea name="company_status_comment" className="form-control" rows="3" value={formData.company_status_comment} onChange={handleChange}></textarea>
                  </div>
                </div>
              </div>

              {/* Section: Répondre aux questions */}
              <div className="border p-3 mt-3">
                <h5 className="font-weight-bold" style={{color: "red"}}>Veuillez répondre aux questions suivantes</h5>

                {[
                  { name: "has_business_identification_number", label: "Avez-vous un numéro d'identification d'entreprise ?" },
                  { name: "agrees_to_provide_official_docs", label: "Acceptez-vous d'envoyer une copie des documents officiels ?" },
                  { name: "agrees_to_provide_partners", label: "Acceptez-vous de fournir les noms de vos partenaires ?" },
                  { name: "can_provide_BCL_BG", label: "Pouvez-vous fournir une BCL ou une BG pour des transactions supérieures à 50 000 € ?" },
                ].map((field, index) => (
                  <div key={index} className="col-md-12 mt-3">
                    <label className="form-label">{field.label}</label>
                    <div>
                      <input type="radio" name={field.name} value="true" checked={formData[field.name] === true} onChange={handleChange} /> Oui
                      <input type="radio" name={field.name} value="false" className="ms-3" checked={formData[field.name] === false} onChange={handleChange} /> Non
                    </div>
                  </div>
                ))}
              </div>

              {/* Bouton de soumission avec loader */}
              <div className="col-md-12 mt-4">
                <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                  {loading ? (
                    <>
                      <span className="spinner-border spinner-border-sm me-2"></span>
                      Mise à jour...
                    </>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </div>
            </form>
              </div>
         </div>
         <div className="col-lg-3 col-12">
             <UserMenuCard />
          </div>
     </div>
    </div>
  );
};

export default UserOfficialInfo;
