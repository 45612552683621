import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAds, setCurrentPage } from "../redux/slices/adSlices/AllAdsSlice";
import ListAdsHome from "./ListAdsHome";
// import { Link } from "react-router-dom";
import RecentUsers from "./users/RecentUsers";
import "../styles/Home.css";
import Banner from "../components/Banner";

const Home = () => {
  const dispatch = useDispatch();
  const { ads, currentPage, totalPages, loading, error } = useSelector(
    (state) => state.ads
  );

  // Local state pour les filtres
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState("Toutes les dates");
  const [selectedDomain, setSelectedDomain] = useState("");

  // State pour la détection de la taille de l'écran
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Met à jour `isMobile` en cas de redimensionnement
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Charger les annonces
  useEffect(() => {
    if (!ads[currentPage]) {
      dispatch(fetchAds(currentPage));
    }
  }, [dispatch, currentPage, ads]);

  // Filtrage par date
  const filterByDate = (ad) => {
    const adDate = new Date(ad.created_at);
    const now = new Date();

    switch (selectedDateRange) {
      case "today": {
        const todayStart = new Date();
        todayStart.setHours(0, 0, 0, 0);
        const todayEnd = new Date();
        todayEnd.setHours(23, 59, 59, 999);
        return adDate >= todayStart && adDate <= todayEnd;
      }
      case "Dernier 7 jours": {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(now.getDate() - 7);
        return adDate >= sevenDaysAgo;
      }
      case "Dernier 30 jours": {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(now.getDate() - 30);
        return adDate >= thirtyDaysAgo;
      }
      case "Cette année": {
        return adDate.getFullYear() === now.getFullYear();
      }
      default:
        return true; // Pas de filtrage
    }
  };

  // Annonces filtrées
  const filteredAds =
    ads[currentPage]?.filter((ad) => {
      const matchesSearchTerm =
        ad.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ad.description.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesDomain = selectedDomain
        ? ad.food_industry_field === selectedDomain
        : true;
      const matchesDate = filterByDate(ad);
      return matchesSearchTerm && matchesDomain && matchesDate;
    }) || [];

  // Gestion de la pagination
  const handlePageChange = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    } else if (direction === "next" && currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  // Réinitialisation des filtres
  const resetFilters = () => {
    setSearchTerm("");
    setSelectedDateRange("Toutes les dates");
    setSelectedDomain("");
    dispatch(fetchAds(currentPage));
  };

  return (
    <div
      className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px", 
        fontFamily: "Nunito", fontWeight: "500", fontStyle: "normal"
      }}
    >

      <Banner />

      <h3 className="mt-3 mb-3" style={{ color: "#003366" }}>
        Les dernières annonces agroalimentaires
      </h3>

      <div className="row justify-content-start">
        <ListAdsHome
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          selectedDomain={selectedDomain}
          setSelectedDomain={setSelectedDomain}
          filteredAds={filteredAds}
          loading={loading}
          error={error}
          resetFilters={resetFilters}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />

        {/* Section latérale */}
        <div className="col-lg-3 col-md-12 col-sm-12">
            <RecentUsers />
        </div>
      </div>
    </div>
  );
};

export default Home;
