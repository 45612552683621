import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/api"; 

// Thunk to fetch ad details by slug
export const fetchAdDetails = createAsyncThunk(
  "adDetails/fetchAdDetails",
  async (adSlug, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/ads/${adSlug}`);
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const adDetailsSlice = createSlice({
  name: "adDetails",
  initialState: {
    ad: null,
    user: null,
    relatedAds: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetAdDetailsState: (state) => {
      state.ad = null;
      state.user = null;
      state.relatedAds = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.ad = action.payload.ad;
        state.user = action.payload.ad.user;
        state.relatedAds = action.payload.related_ads;
      })
      .addCase(fetchAdDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetAdDetailsState } = adDetailsSlice.actions;
export default adDetailsSlice.reducer;
