import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import UserMenuCard from "./UserMenuCard"; 

const UserUnsubscribe = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const userEmail = ""; 
  const navigate = useNavigate(); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fonction pour confirmer la désinscription
  const handleUnsubscribe = () => {
    const confirmation = window.confirm(
      `Êtes-vous sûr de vouloir supprimer définitivement votre compte ${userEmail} ?\nToutes vos données seront perdues !`
    );

    if (confirmation) {
      alert("Votre compte a été supprimé avec succès.");
      navigate("/"); // Redirection après suppression
    }
  };

  return (
    <div className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito",
        fontWeight: "500",
        fontStyle: "normal",
        padding: "30px",
      }}
    >
      <div className="row">
        
        {/* Colonne principale (col-9) avec une Card */}
        <div className="col-lg-9 col-12 d-flex justify-content-center align-items-center text-center" style={{ height: "80vh" }}>
          <div className="card p-4 shadow" style={{ width: "100%", maxWidth: "600px" }}> {/* ✅ Ajout d'une Card */}
            <div className="card-body">
              <h3 className="card-title mb-3 text-danger">Désinscription</h3>
              <p className="card-text">
                Vous souhaitez supprimer définitivement votre compte <strong>ESPACE NEGOCE</strong> "<strong>{userEmail}</strong>" ?
              </p>
              <p className="card-text text-muted">
                En supprimant votre compte, vous n'aurez plus accès à cet espace. Toutes les informations concernant vos annonces,
                vos contacts, votre messagerie et vos unités restantes seront définitivement perdues.
              </p>
              <p className="card-text">
                Pour demander la suppression de votre compte, cliquez sur le bouton ci-dessous :
              </p>

              <button 
                className="btn btn-danger mt-3 w-100"
                onClick={handleUnsubscribe}
              >
                Se désinscrire
              </button>
            </div>
          </div>
        </div>

        {/* Colonne UserMenuCard (col-3) */}
        <div className="col-lg-3 col-12 mb-3">
          <UserMenuCard />
        </div>

      </div>
    </div>
  );
};

export default UserUnsubscribe;
