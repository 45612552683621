import React, { useRef } from 'react';

const FormAds = ({
  formData,
  handleChange,
  handleFileChange,
  handleRemoveFile,
  handleSubmit,
  loading,
  error,
  data,
  AGRO_DOMAINS,
}) => {
  const fileInputRef = useRef(null);

  return (
    <div className="card p-4">
      <h2 className="text-center mb-4">Créer une annonce</h2>

      {/* Affichage des états */}
      {loading && <p className="text-center text-primary">Soumission en cours...</p>}
      {error && <p className="text-center text-danger">{JSON.stringify(error)}</p>}
      {data && data.message && typeof data.message === 'string' && (
        <p className="text-center text-success">{data.message}</p>
      )}

      <form onSubmit={handleSubmit}>
        {/* Champs obligatoires */}
        <div className="mb-3">
          <label htmlFor="need_type" className="mb-2">Type de besoin *</label>
          <select
            className="form-control"
            name="need_type"
            value={formData.need_type}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionner</option>
            <option value="Achat">Achat</option>
            <option value="Vente">Vente</option>
            <option value="Recherche de partenaire">Recherche de partenaire</option>
            <option value="Recherche de conseiller">Recherche de conseiller</option>

          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="food_industry_field" className="mb-2">Domaine d'agroalimentaire *</label>
          <select
            className="form-control"
            name="food_industry_field"
            value={formData.food_industry_field}
            onChange={handleChange}
            required
          >
            {AGRO_DOMAINS.map((domain) => (
              <option key={domain.value} value={domain.value}>
                {domain.label}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="title" className="mb-2">Titre de l'annonce *</label>
          <input
            type="text"
            className="form-control"
            name="title"
            placeholder="Ex: Achat de miel bio"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="mb-2">Description *</label>
          <textarea
            className="form-control"
            name="description"
            placeholder="Description complète la plus détaillée possible(100 caractères minimum). Profitez de cet espace pour bien décrire votre annonce. Pensez par exemple à préciser les quantités, les atouts, l'intérêt pour votre interlocuteur, etc ...  Plus votre annonce sera détaillée et précise, plus vous aurez de chance de trouver rapidement un bon contact."            value={formData.description}
            onChange={handleChange}
            rows="5"
            required
          ></textarea>
        </div>

        {/* Champs optionnels */}
        <div className="row">
          <div className="col-6 mb-3">
            <label htmlFor="quantity" className="mb-2">Quantité</label>
            <input
              type="text"
              className="form-control"
              name="quantity"
              placeholder="Ex: 20 tonnes"
              value={formData.quantity}
              onChange={handleChange}
            />
          </div>

          <div className="col-6 mb-3">
            <label htmlFor="price" className="mb-2">Prix</label>
            <input
              type="text"
              className="form-control"
              name="price"
              placeholder="Ex: 1000 euros la tonne"
              value={formData.price}
              onChange={handleChange}
            />
          </div>

          <div className="col-6 mb-3">
            <label htmlFor="brand" className="mb-2">Marque</label>
            <input
              type="text"
              className="form-control"
              name="brand"
              placeholder="Ex: Golden"
              value={formData.brand}
              onChange={handleChange}
            />
          </div>

          <div className="col-6 mb-3">
            <label htmlFor="restrictions" className="mb-2">Restrictions</label>
            <input
              type="text"
              className="form-control"
              name="restrictions"
              placeholder="Ex: Aucune restriction"
              value={formData.restrictions}
              onChange={handleChange}
            />
          </div>

          <div className="col-6 mb-3">
            <label htmlFor="shipping_conditions" className="mb-2">Conditions de livraison</label>
            <input
              type="text"
              className="form-control"
              name="shipping_conditions"
              placeholder="Ex: Livraison incluse"
              value={formData.shipping_conditions}
              onChange={handleChange}
            />
          </div>

          <div className="col-6 mb-3">
            <label htmlFor="transaction_details" className="mb-2">Précisions sur la transaction</label>
            <input
              type="text"
              className="form-control"
              name="transaction_details"
              placeholder="Ex: Paiement à réception"
              value={formData.transaction_details}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Champ pour l'upload des médias */}
        <div className="mb-3">
          <label htmlFor="media" className="mb-2">Une Image correspondant à votre annonce</label>
          <div
            className="p-4 text-center"
            style={{
              border: '2px dashed #003366',
              cursor: 'pointer',
              borderRadius: '5px',
            }}
            onClick={() => fileInputRef.current.click()}
          >
            {formData.media.length === 0
              ? 'Cliquer pour sélectionner une ou plusieurs images de votre annonce'
              : `${formData.media.length} fichiers sélectionnés`}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*,video/*"
            multiple
            style={{ display: 'none' }}
          />
          <div className="d-flex flex-wrap mt-3">
            {formData.media.map((item, index) => (
              <div
                key={index}
                className="me-3 mb-3 text-center"
                style={{ width: '100px' }}
              >
                {item.file.type.startsWith('image/') ? (
                  <img
                    src={item.preview}
                    alt="Preview"
                    style={{ width: '100%', height: 'auto', borderRadius: '5px' }}
                  />
                ) : (
                  <video
                    src={item.preview}
                    controls
                    style={{ width: '100%', height: 'auto', borderRadius: '5px' }}
                  ></video>
                )}
                <button
                  type="button"
                  className="btn btn-danger btn-sm mt-1"
                  onClick={() => handleRemoveFile(index)}
                >
                  Supprimer
                </button>
              </div>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-success w-100"
          disabled={loading}
        >
          {loading ? 'En cours de création...' : 'Soumettre'}
        </button>
      </form>
    </div>
  );
};

export default FormAds;
