import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { sendResetPasswordEmail, clearState } from '../../redux/slices/authSlice'; // Action pour envoyer l'email
import '../../styles/auth.css';

function ForgetPassword() {
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();
  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.auth);

  // Gestion des états après soumission
 
  // Gestion de la soumission du formulaire
 

  return (
    <div className="container mt-5">
      <div className="col-lg-6 offset-lg-3">
        <div className="card p-4"
            style={{
                maxWidth: '400px',
            }}
        >
          <h2>Mot de passe oublié</h2>
          <form >
            {/* Email */}
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Entrer votre email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {/* Bouton pour envoyer le lien */}
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={isLoading}
            >
              {isLoading ? 'Envoi...' : 'Envoyer un lien de réinitialisation'}
            </button>
          </form>

          {/* Lien pour se connecter */}
          <div className="mt-3 text-center">
            <Link to="/login" style={{ textDecoration: 'none', color: '#003366' }}>
              Revenir à la connexion
            </Link>
          </div>

          {/* Lien pour inscription */}
          <div className="mt-3 text-center">
            <Link to="/register" style={{ textDecoration: 'none', color: '#003366' }}>
              Vous n'avez pas de compte ? Créez-en un ici
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
