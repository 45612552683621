import React from "react";
import { Link } from "react-router-dom";

const RegistrationSuccess = () => {
  return (
    <div className="container mt-5">
      <div className="col-lg-6 offset-lg-3">
        <div
          className="card p-4 text-center"
          style={{
            maxWidth: "400px",
          }}
        >
          <h2>Inscription réussie !</h2>
          <p>
            Votre inscription a bien été effectuée. Vérifiez votre boîte mail
            pour valider votre compte.
          </p>
          <Link to="/login" className="btn btn-primary">
            Aller à la page de connexion
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccess;
