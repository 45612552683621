// src/components/Footer.jsx
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-light text-center  py-3 mt-auto" style={{ color: "#003366" }}>
      <p>&copy; 2025 Espace Negoce. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
