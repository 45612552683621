import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/api";

const initialState = {
  ads: [],
  loading: false,
  error: null,
  currentPage: 1,
  totalPages: 1,
};

// Thunk pour récupérer les annonces d'un utilisateur
export const fetchUserAds = createAsyncThunk("userAds/fetchUserAds", async (page = 1, { getState }) => {
  try {
    const token = getState().auth.token; // Récupérer le token de l'utilisateur connecté
    const response = await axios.get(`${API_BASE_URL}/my-ads?page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || "Erreur lors du chargement des annonces.";
  }
});

const userAdsSlice = createSlice({
  name: "userAds",
  initialState,
  reducers: {
    resetUserAdsState: (state) => {
      state.ads = [];
      state.loading = false;
      state.error = null;
      state.currentPage = 1;
      state.totalPages = 1;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserAds.fulfilled, (state, action) => {
        state.loading = false;
        state.ads = action.payload.data;
        state.currentPage = action.payload.current_page;
        state.totalPages = action.payload.last_page;
      })
      .addCase(fetchUserAds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Erreur lors de la récupération des annonces.";
      });
  },
});

export const { resetUserAdsState, setCurrentPage } = userAdsSlice.actions;
export default userAdsSlice.reducer;