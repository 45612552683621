import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { fetchAdDetails, resetAdDetailsState } from "../../redux/slices/adSlices/adDetailsSlice";
import ListAd from "./ListAd";
import Button from "../../components/UI/Button";

const AdDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ad, user, relatedAds, loading, error } = useSelector((state) => state.adDetails);
  const { slug } = useParams(); // Récupérer le slug depuis les paramètres de la route
  const isMobile = window.innerWidth < 768; // Détecter si l'utilisateur est sur un écran mobile

  useEffect(() => {
    dispatch(fetchAdDetails(slug)); // Charger les détails de l'annonce avec le slug

    // Nettoyer l'état lors du démontage du composant
    return () => {
      dispatch(resetAdDetailsState());
    };
  }, [dispatch, slug]);

  const handleContactUser = () => {
    navigate("/messenger");
  };

  return (
    <div
      className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "230px",
        display: loading ? "flex" : "block", 
        justifyContent: loading ? "center" : "initial",
        alignItems: loading ? "center" : "initial",
        height: loading ? "70vh" : "auto", 
        fontFamily: "Nunito", fontWeight: "500", fontStyle: "normal",
        paddingTop: "25px"
      }}
    >
      {loading && (
       <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          ></div>
          <span className="mt-2">Chargement en cours...</span> 
        </div>
     
      )}
      {!loading && error && (
        <div className="text-center">
          <p className="text-danger">
            Erreur : {typeof error === "object" ? JSON.stringify(error) : error}
          </p>
        </div>
      )}
      {!loading && !error && ad && (
        <div className="row g-4">
          {/* Colonne pour les détails de l'annonce */}
          <div className="col-12 col-lg-6">
            <div className="card" style={{ padding: "0px" }}>
              {/* Ajout d'un wrapper avec padding pour le contenu (hors image) */}
              <div style={{ padding: "10px 15px 0px 10px" }}>
                <div className="d-flex justify-content-between align-items-center ">
                  <div className="card-body p-0"> {/* Suppression du padding par défaut de card-body */}
                    <h4>{ad.title}</h4>
                    <p className="text-muted">
                      Posté le : {new Date(ad.created_at).toLocaleDateString("fr-FR")}
                    </p>
                  </div>
                  <div>
                    <span className={`button-${ad.need_type === "Achat" ? "buy" : "sell"} mb-2 mb-md-0`}>
                      {ad.need_type}
                    </span>
                  </div>
                </div>
              </div>

              {/* Image sans padding */}
              <img
                src={
                  ad.media && ad.media.length > 0
                    ? ad.media[0].file_path
                    : "https://via.placeholder.com/600x300?text=Pas+d'image"
                }
                alt={ad.title}
                className="img-fluid"
                style={{
                  maxHeight: "400px",
                  objectFit: "cover",
                  width: "100%",
                }}
              />

              {/* Ajout de padding pour la description et les détails */}
              <div style={{ padding: "10px 18px 0px 20px" }}>
                <div className="mt-2">
                  <p>{ad.description || "Aucune description disponible."}</p>
                  <hr />
                </div>
                <div className="mt-2 row">
                  <div className="col-6">
                    <p>
                      <strong>Quantité :</strong> {ad.quantity || "Non spécifiée"}
                    </p>
                    <p>
                      <strong>Prix :</strong> {ad.price || "Non spécifié"}
                    </p>
                  </div>
                  <div className="col-12">
                    <p>
                      <strong>Marque :</strong> {ad.brand || "Non spécifiée"}
                    </p>
                    <p>
                      <strong>Restrictions :</strong> {ad.restrictions || "Non spécifiées"}
                    </p>

                    <p>
                      <strong>Conditions de livraison :</strong> {ad.restrictions || "Non spécifiées"}
                    </p>

                    <p>
                      <strong>Précisions sur la transaction :</strong> {ad.restrictions || "Non spécifiées"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* Colonne pour les annonces similaires */}
          <div className="col-12 col-lg-6">
            {/* Informations de l'utilisateur */}
            {user && (
              <div className="card p-4 mb-4">
                <h5 className="mb-4">Informations sur l'annonceur </h5>
                <div className="d-flex flex-wrap justify-content-between">
                  <p>
                    <strong>Nom :</strong> {`${user.firstname} ${user.lastname}`}
                  </p>
                  <p>
                    <strong>Email :</strong> {user.email}
                  </p>
                  <p>
                    <strong>Téléphone :</strong> {`${user.country_code} ${user.phone}`}
                  </p>
                  <p>
                    <strong>Pays :</strong> {user.country}
                  </p>
                </div>
                <Button
                    label={`Envoyer un message à ${user.firstname}`}
                    onClick={handleContactUser}
                    className="btn-primary"
                  />

              </div>
            )}
            <div className="">
              <h3 className="mt-3 mb-3" style={{ color: "#003366" }}>
                Annonces similaires 
              </h3>
              <div className="row g-3">
                {relatedAds && relatedAds.length > 0 ? (
                  relatedAds.map((relatedAd) => (
                    <div className="col-12" key={relatedAd.id}>
                      <ListAd
                        slug={relatedAd.slug}
                        imageUrl={
                          relatedAd.media?.[0]?.file_path || "https://via.placeholder.com/150"
                        }
                        title={relatedAd.title}
                        author={`${
                          relatedAd.user?.firstname || "Auteur"
                        } ${relatedAd.user?.lastname || ""}`}
                        need_type={relatedAd.need_type}
                        food_industry_field={relatedAd.food_industry_field}
                        description={relatedAd.description}
                        createdAt={relatedAd.created_at}
                      />
                    </div>
                  ))
                ) : (
                  <p>Aucune annonce similaire trouvée.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdDetails;
