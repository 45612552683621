import React, { useEffect, useState } from "react";
import { FaPaperPlane } from "react-icons/fa";
import { networkingData } from "../../utils/Constants";

const Networking = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container mt-4"
    style={{
      maxWidth: "100%",
      marginLeft: isMobile ? "0px" : "210px",
      fontFamily: "Nunito", fontWeight: "500", fontStyle: "normal"
    }}
    >
      {/* ✅ Titre personnalisé */}
      <h4 className="mb-2 mt-4">
        Bonne nouvelle <span role="img" aria-label="smiley">😊</span> Nicaise, ces membres sont dans le même secteur d'activité que vous!
      </h4>

      {/* ✅ Grid Bootstrap */}
      <div className="row justify-content-center">
        {networkingData.map((member) => (
          <div key={member.id} className="col-12 col-sm-6 col-md-4 col-lg-2 mb-1">
            <div className="card p-3 text-center shadow-sm border rounded" style={{ margin: "10px auto",  }}>

              {/* ✅ Avatar circulaire généré dynamiquement */}
              <img
                src={`https://api.dicebear.com/7.x/pixel-art/svg?seed=User${member.id}`}
                alt="Avatar"
                className="mx-auto d-block rounded-circle"
                style={{ width: "70px", height: "70px" }}
              />

              {/* ✅ Informations du membre */}
              <p className="mt-3 mb-1 fw-bold">Domaine d'activité: {member.domain}</p>
              <p className="fw-bold text-primary">{member.country}</p>

              {/* ✅ Bouton "Initier un contact" */}
              <button className="btn btn-primary w-100 btn-sm">
                <FaPaperPlane className="me-2" /> Message
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Networking;
