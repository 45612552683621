import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecentUsers } from "../../redux/slices/authSlices/userSlice";
import { formatDistanceToNow, format } from "date-fns";
import { fr } from "date-fns/locale";
import { Link } from "react-router-dom";

const RecentUsers = () => {
  const dispatch = useDispatch();
  const { recentUsers, status, error } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchRecentUsers());
  }, [dispatch]);

  return (
    <div className="card" style={{ margin: "-5px", padding: "0px" }}>
      <div className="card-body">
        <h6 className="card-title" style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "20px", color: "#003366" }}>
             Bienvenue aux nouveaux négociants !
        </h6>
        {status === "loading" && <p>Chargement...</p>}
        {status === "failed" && <p style={{ color: "red" }}>{error}</p>}
        <ul className="list-unstyled" style={{  }}>
          {recentUsers.length > 0 ? (
            recentUsers.map((user, index) => (
              <li key={index} className="mb-2">
               <strong style={{ color: "#036", fontWeight: "200" }}> {user.firstname}</strong> {" "} 
                {index === 0
                  ? `${formatDistanceToNow(new Date(user.created_at), {
                      locale: fr,
                      addSuffix: true,
                    })}`
                  : format(new Date(user.created_at), "dd/MM/yyyy", { locale: fr })}
               {" "} - {" "} <strong style={{ color: "#ff2705", fontWeight: "200" }}>{user.country}</strong> 
              </li>
            ))
          ) : (
            <p>Aucun utilisateur récent.</p>
          )}
        </ul>
        <div className="d-flex justify-content-center">
          <Link
            to="/register"
            className="btn btn-primary"
            style={{
              width: "100%",
              fontWeight: "bold",
              backgroundColor: "#036",
              border: "none",
              color: "white",
            }}
          >
            Rejoignez la communauté
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RecentUsers;
