import React, { useEffect, useState } from "react";

const About = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito",
        fontWeight: "500",
        fontStyle: "normal",
        padding: "30px",
      }}
    >
      <div className="row">
        
        {/* ✅ Colonne principale (col-9) avec une Card */}
        <div className="col-lg-12 col-12 d-flex justify-content-center align-items-center">
          <div className="card p-4 shadow" style={{ width: "100%", maxWidth: "800px" }}> 
            <div className="card-body">
              <h3 className="card-title mb-3 text-center text-primary">À propos de nous</h3>
              <p className="card-text text-muted text-justify">
                Bienvenue sur <strong>Espace Négoce</strong> ! Nous sommes une plateforme dédiée à la mise en relation des professionnels du commerce et de l'industrie.
                Notre objectif est de faciliter les transactions et de créer un environnement propice aux affaires en Afrique et dans le monde entier.
              </p>

              <h5 className="mt-4 text-primary">Notre mission</h5>
              <p className="text-muted">
                Nous visons à offrir un espace sécurisé et efficace où acheteurs et vendeurs peuvent interagir, négocier et conclure des transactions en toute confiance.
              </p>

              <h5 className="mt-4 text-primary">Nos valeurs</h5>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">✔️ Transparence et confiance</li>
                <li className="list-group-item">✔️ Simplicité et efficacité</li>
                <li className="list-group-item">✔️ Innovation et accessibilité</li>
              </ul>

              <h5 className="mt-4 text-primary">Pourquoi nous choisir ?</h5>
              <p className="text-muted">
                🌍 Présence sur plusieurs marchés <br />
                🤝 Réseau professionnel en expansion <br />
                🚀 Plateforme intuitive et sécurisée
              </p>
            </div>
          </div>
        </div>

       
      </div>
    </div>
  );
};

export default About;
