import React from "react";

const SearchBar = ({ searchTerm, handleSearchChange }) => {
  return (
    <div className="position-relative w-100">
      <i
        className="bi bi-search search-icon"
        style={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          color: "#888",
        }}
      ></i>
      <input
        type="text"
        className="form-control"
        placeholder="Rechercher par mots clés"
        value={searchTerm}
        onChange={handleSearchChange}
        style={{
          paddingLeft: "35px",
        }}
      />
    </div>
  );
};

export default SearchBar;
