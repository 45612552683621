import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../redux/slices/authSlices/RegisterSlice";
import { clearError } from "../../redux/slices/authSlices/authSlice";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaEye, FaEyeSlash, FaUserPlus } from "react-icons/fa";
import axios from "axios";
import Button from "../../components/UI/Button";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  // States
  const [lastname, setLastName] = useState("");
  const [firstname, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Loader overlay
  const [validationErrors, setValidationErrors] = useState({}); // Erreurs spécifiques

  // Fonction pour récupérer l'adresse IP
  const fetchIpAddress = async () => {
    try {
      const response = await axios.get("https://api64.ipify.org?format=json");
      setIpAddress(response.data.ip);
    } catch (error) {
      console.error("Erreur lors de la récupération de l’adresse IP :", error);
    }
  };

  useEffect(() => {
    fetchIpAddress();
  }, []);

  // Gestion des erreurs
  const clearErrorMessage = () => {
    dispatch(clearError());
    setValidationErrors({});
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Active le loader

    const formData = {
      lastname,
      firstname,
      email,
      phone,
      country_code: countryCode,
      password,
      password_confirmation,
    };

    const result = await dispatch(registerUser(formData));
    if (result.meta.requestStatus === "fulfilled") {
      setIsSubmitting(false); // Désactive le loader
      navigate("/register/success");
    } else {
      setIsSubmitting(false); // Désactive le loader en cas d'erreur
      setValidationErrors(result.payload.errors || {}); // Capture les erreurs spécifiques
    }
  };

  return (
    <div className="container-fluid position-relative">
      {/* Overlay avec spinner */}
      {isSubmitting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="spinner-border text-light" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}

      <div
        className="alert alert-info"
        role="alert"
        style={{
          color: "#000",
          padding: "10px",
          // marginTop: "",
          borderBottom: "1px solid #ddd",
        }}
      >
        <marquee behavior="scroll" direction="left" scrollAmount="4">
          <strong>Actuellement</strong> : 580,067 inscriptions, dont 289,567
          membres actifs, sur 197 pays. <strong>Espace Negoce</strong> est une
          plateforme dédiée aux professionnels du négoce international. Une{" "}
          <strong>inscription gratuite</strong> est requise pour accéder à
          l'ensemble de nos services. Pour vous inscrire, merci de bien vouloir
          remplir le formulaire ci-dessous et de procéder à la validation de
          votre profil dans votre espace. Les inscriptions non pertinentes ou
          douteuses seront supprimées. D'avance, nous vous souhaitons de faire
          de bonnes affaires 😃
        </marquee>
      </div>

      <div className="col-lg-8 offset-lg-2">
        <div className="card p-4">
          <h2>Créer un compte</h2>

          {/* Message d'erreur global */}
          {error && (
            <div
              className="alert alert-danger"
              role="alert"
              onClick={clearErrorMessage}
              style={{ cursor: "pointer" }}
            >
              {error.message || "Une erreur est survenue. Veuillez réessayer."}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            {/* Ligne 1 : Nom et Prénom */}
            <div className="row mb-3">
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nom"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                {validationErrors.lastname && (
                  <small className="text-danger">{validationErrors.lastname[0]}</small>
                )}
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Prénom(s)"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                {validationErrors.firstname && (
                  <small className="text-danger">{validationErrors.firstname[0]}</small>
                )}
              </div>
            </div>

            {/* Ligne 2 : Email et Téléphone */}
            <div className="row mb-3">
              <div className="col-6">
                <div >
                  <input
                    type="email"
                    className={`form-control ${validationErrors.email ? "is-invalid" : ""}`}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      const value = e.target.value;
                      setEmail(value);

                      if (!value) {
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          email: ["L'email est obligatoire."],
                        }));
                      } else if (!/\S+@\S+\.\S+/.test(value)) {
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          email: ["L'email n'est pas valide."],
                        }));
                      } else {
                        setValidationErrors((prevErrors) => {
                          const { email, ...rest } = prevErrors;
                          return rest;
                        });
                      }
                    }}
                    required
                  />
                  {validationErrors.email && (
                    <small className="text-danger" style={{ position: "absolute", top: "43%", left: "28px"}}>
                      {validationErrors.email[0]}
                    </small>
                  )}
                </div>
              </div>

              <div className="col-6">
                <PhoneInput
                  country={"bj"} 
                  value={phone}
                  onChange={(value, countryData) => {
                    console.log("Country Data:", countryData); // Debug pour vérifier le contenu de `countryData`
                    setPhone(value);

                    // Sauvegarde correcte du code pays dans `country_code`
                    if (countryData && countryData.dialCode) {
                      setCountryCode(countryData.dialCode); // Pas besoin d'ajouter `+` ici si votre backend le fait.
                    } else {
                      setCountryCode("1"); // Par défaut si aucun code trouvé
                    }
                  }}
                  placeholder="Téléphone"
                  inputStyle={{
                    width: "100%",
                    borderRadius: "5px",
                  }}
                />
                {validationErrors.phone && (
                  <small className="text-danger">{validationErrors.phone[0]}</small>
                )}
              </div>

            </div>

            {/* Ligne 3 : Mot de passe et Confirmation mot de passe */}
            <div className="row mb-3">
              <div className="col-6 position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span>
                  {validationErrors.password && (
                    <small className="text-danger" style={{ marginTop:"15px",  position: "absolute", top: "40%", left: "15px" }}>{validationErrors.password[0]}</small>
                  )}
                </span>
                <span
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "33%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
               
              </div>
              <div className="col-6 position-relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Confirmer le mot de passe"
                  value={password_confirmation}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                {validationErrors.password_confirmation && (
                  <small className="text-danger">
                    {validationErrors.password_confirmation[0]}
                  </small>
                )}
                <span
                  className="password-toggle"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}

                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "33%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
                
              </div>
            </div>

            {/* Conditions et adresse IP */}
            <div className="form-group d-flex align-items-center" style={{marginTop:"20px"}}>
              <input
                type="checkbox"
                id="accept"
                className="form-check-input me-2 mb-2"
                required
              />
              <label
                htmlFor="accept"
                style={{ fontSize: "17px", marginLeft: "5px" }}
              >
                J'accepte les{" "}
                <a
                  href="https://www.google.com/policies/terms/"
                  style={{ color: "" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  conditions générales d'utilisation de Espace Négoce
                </a>
              </label>
            </div>
            <p style={{ fontSize: "14px", color: "red", marginTop:"10px" }}>
              <strong>Attention: </strong> vous êtes engagé à faire preuve de transparence et de
              responsabilité dans l'utilisation de notre plateforme.{" "}
              <strong> Votre adresse IP : {ipAddress} </strong>
            </p>

            {/* Bouton de soumission */}
            <Button
              type="submit"
              label={isSubmitting ? "Traitement en cours..." : "Créer un compte"}
              icon={<FaUserPlus />}
              isLoading={isSubmitting}
              className="btn-primary w-100"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
