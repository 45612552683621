import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserMenuCard from "./UserMenuCard";

const AlertEmail = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [keywords, setKeywords] = useState(["maïs", "soja", "ciment", "escargot"]); // ✅ Mots-clés initiaux
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Ajoute un mot-clé à la liste
  const handleAddKeyword = (e) => {
    e.preventDefault();
    const newKeyword = inputValue.trim().toLowerCase();
    if (newKeyword && !keywords.includes(newKeyword)) {
      setKeywords([...keywords, newKeyword]);
      setInputValue(""); // Réinitialise le champ
    }
  };

  // Supprime un mot-clé
  const handleRemoveKeyword = (keywordToRemove) => {
    setKeywords(keywords.filter((keyword) => keyword !== keywordToRemove));
  };

  return (
    <div
      className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito",
        fontWeight: "500",
        fontStyle: "normal",
        padding: "30px",
      }}
    >
      <div className="row">
        <div className="col-lg-9">
          <div className="card p-4" style={{ maxWidth: "800px" }}>
            <h3 className="mt-4 mb-3" style={{ color: "#003366" }}>
              Recevoir chaque matin les alertes par email !
            </h3>
            <p>
              Vous souhaitez être prévenu dès qu'un type d'affaire est diffusé sur
              <strong> Espace Negoce</strong> ? <br />
            </p>
            <p>
              Indiquez ci-dessous les termes qui caractérisent ces affaires.
              Votre bulletin d'alerte sera envoyé chaque matin avant 9h.
            </p>

            {/* Formulaire */}
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group">
                <label className="mt-2 mb-2">
                  Entrez des mots-clés (ex: prune, soja, fromage...) :
                </label>

                {/* Ligne pour input et bouton */}
                <div className="row">
                  <div className="col-lg-9 col-md-8 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ajouter un mot-clé"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      style={{ height: "40px" }}
                    />
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 mt-2 mt-md-0">
                    <button
                      className="btn btn-primary w-100"
                      type="button"
                      onClick={handleAddKeyword}
                    >
                      Enregister
                    </button>
                  </div>
                </div>
              </div>

              {/* Affichage des mots-clés sélectionnés */}
              {keywords.length > 0 && (
                <div className="mt-3">
                  <h6>Mots-clés enregistrés :</h6>
                  <div>
                    {keywords.map((keyword, index) => (
                      <span
                        key={index}
                        className="badge  text-white badge-pill m-1"
                        style={{ cursor: "pointer", padding: "8px 12px", backgroundColor: "#00cc44", color:"#fff", fontSize: "14px" }}
                        onClick={() => handleRemoveKeyword(keyword)}
                        >
                        {keyword} ✖
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </form>

            <p className="mt-3 text-muted">
              Si vous ne souhaitez plus recevoir les alertes, cliquez sur le lien suivant :
              <Link to="/unsubscribe"> Ne plus recevoir les alertes</Link>
            </p>
          </div>
        </div>
        <div className="col-lg-3 col-12">
          <UserMenuCard />
        </div>
      </div>
    </div>
  );
};

export default AlertEmail;
