import React, { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import des icônes Bootstrap

const DisplayToggle = ({ viewMode, setViewMode }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <span style={{ fontWeight: "bold", color: "#333" }}>Affichage:</span>
      {/* Mode Liste */}
      <button
        onClick={() => setViewMode("list")}
        style={{
          padding: "8px",
          border: "2px solid",
          borderColor: viewMode === "list" ? "#1B2C59" : "#ccc",
          backgroundColor: viewMode === "list" ? "#1B2C59" : "#fff",
          color: viewMode === "list" ? "#fff" : "#1B2C59",
          borderRadius: "5px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "40px",
          height: "40px",
        }}
      >
        <i className="bi bi-list-ul" style={{ fontSize: "20px" }}></i>
      </button>

      {/* Mode Grille */}
      <button
        onClick={() => setViewMode("card")}
        style={{
          padding: "8px",
          border: "2px solid",
          borderColor: viewMode === "card" ? "#1B2C59" : "#ccc",
          backgroundColor: viewMode === "card" ? "#1B2C59" : "#fff",
          color: viewMode === "card" ? "#fff" : "#1B2C59",
          borderRadius: "5px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "40px",
          height: "40px",
        }}
      >
        <i className="bi bi-grid-3x3-gap" style={{ fontSize: "20px" }}></i>
      </button>
    </div>
  );
};

export default DisplayToggle;
