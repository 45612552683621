import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, ENDPOINTS } from "../../../utils/api";

// Action pour récupérer les annonces
export const fetchAds = createAsyncThunk("ads/fetchAds", async (page = 1) => {
  try {
    const response = await api.get(`${ENDPOINTS.ADS}?page=${page}`);
    return response.data; 
  } catch (error) {
    console.error("Erreur lors de la récupération des annonces :", error.message);
    throw error;
  }
});

const AllAdsSlice = createSlice({
  name: "ads",
  initialState: {
    ads: {},
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null,
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAds.fulfilled, (state, action) => {
        state.loading = false;
        state.ads[action.payload.current_page] = action.payload.data; 
        state.currentPage = action.payload.current_page;
        state.totalPages = action.payload.last_page;
      })
      .addCase(fetchAds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setCurrentPage } = AllAdsSlice.actions;
export default AllAdsSlice.reducer;
