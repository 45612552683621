import React, { useEffect, useState, useRef } from "react";
import { FaPaperPlane } from "react-icons/fa";
import "../../styles/chat.css";

const contacts = [
  { id: 1, name: "Nicaise AYIMANDE", lastMessage: "Last message..." },
  { id: 2, name: "Darius HOUESSOU-KODE", lastMessage: "Last message..." },
  { id: 3, name: "Bienvenu Houedikin", lastMessage: "Last message..." },
];

const initialMessages = [
  { id: 1, sender: "Nicaise AYIMANDE", text: "Hi there!", time: "2024-07-27 15:00", isMe: false },
  { id: 2, sender: "Me", text: "Hello!", time: "2024-07-27 15:02", isMe: true },
];

const Chat = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [selectedContact, setSelectedContact] = useState(contacts[0]);
  const [messages, setMessages] = useState(initialMessages);
  const [newMessage, setNewMessage] = useState("");
  const chatBodyRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ✅ Fonction pour envoyer un message
  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;

    const newMsg = {
      id: messages.length + 1,
      sender: "Me",
      text: newMessage,
      time: new Date().toISOString().slice(0, 16).replace("T", " "), // Format YYYY-MM-DD HH:mm
      isMe: true,
    };

    setMessages([...messages, newMsg]);
    setNewMessage(""); // Réinitialiser l'input

    // ✅ Scroller automatiquement en bas après l'envoi du message
    setTimeout(() => {
      chatBodyRef.current?.scrollTo({ top: chatBodyRef.current.scrollHeight, behavior: "smooth" });
    }, 100);
  };

  return (
    <div className="container mt-4" 
      style={{ 
        maxWidth: "100%", 
        fontFamily: "Nunito", 
        fontWeight: "500",
        marginLeft: isMobile ? "0px" : "210px",
      }}
    >
      <div className="row">
        {/* ✅ Liste des contacts */}
        <div className="col-md-5 col-lg-4 mb-3">
          <div className="card shadow-sm border rounded" style={{  padding: "0px", borderRadius: "10px",  margin: "25px auto", }}>
            <div className="contacts-container">
              <h4 className="contacts-title">Messagerie</h4>
              {contacts.map((contact) => (
                <div
                  key={contact.id}
                  className={`contact-item ${selectedContact.id === contact.id ? "selected" : ""}`}
                  onClick={() => setSelectedContact(contact)}
                >
                  <img
                    src={`https://api.dicebear.com/7.x/pixel-art/svg?seed=User${contact.id}`}
                    alt="Avatar"
                    className="contact-avatar"
                  />
                  <div className="contact-info">
                    <strong className="contact-name">{contact.name}</strong>
                    <p className="contact-last-message">{contact.lastMessage}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* ✅ Zone de chat */}
        <div className="col-md-7 col-lg-8">
          <div className="card shadow-sm border rounded" style={{ maxWidth: "800px", padding: "0px", borderRadius: "10px",  margin: "25px auto", }}>
            {/* ✅ En-tête du chat */}
            <div className="card-header d-flex align-items-center">
              <img
                src={`https://api.dicebear.com/7.x/pixel-art/svg?seed=User${selectedContact.id}`}
                alt="Avatar"
                className="rounded-circle me-3"
                style={{ width: "40px", height: "40px" }}
              />
              <strong>{selectedContact.name}</strong>
            </div>

            {/* ✅ Messages */}
            <div className="card-body chat-body p-3" ref={chatBodyRef} style={{ height: "450px", overflowY: "auto", background: "#f8f9fa" }}>
              {messages.map((msg) => (
                <div key={msg.id} className={`d-flex mb-3 ${msg.isMe ? "justify-content-end" : "justify-content-start"}`}>
                  <div className={`p-2 rounded ${msg.isMe ? "bg-chat text-white" : "bg-light"}`} style={{ maxWidth: "60%" }}>
                    <p className="mb-1">{msg.text}</p>
                    <small className={`d-block  ${msg.isMe ? "text-white" : "text-muted"}`} style={{fontSize: "12px"}}>
                      {msg.time}
                    </small>
                  </div>
                </div>
              ))}
            </div>

            {/* ✅ Zone de saisie */}
            <div className="card-footer d-flex align-items-center p-2">
              <input
                type="text"
                className="form-control me-2 chat-input"
                placeholder="Votre message ici..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleSendMessage()} 
               
              />
              <button className="chat-button" onClick={handleSendMessage} 
               
              >
                <FaPaperPlane size={18} />
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Chat;
