import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { ENDPOINTS } from "../../../utils/api";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (formData, { rejectWithValue }) => {
    try {
      // 1️⃣ Récupérer le token CSRF avant de faire la requête de connexion
      await api.get("/sanctum/csrf-cookie", { withCredentials: true });

      // 2️⃣ Faire la requête de connexion avec le cookie CSRF
      const response = await api.post(ENDPOINTS.LOGIN, formData, { withCredentials: true });

      const { token, user } = response.data; 

      // 3️⃣ Stocker le token dans sessionStorage
      sessionStorage.setItem("token", token);

      return { token, user };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
