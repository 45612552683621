import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserAds, setCurrentPage } from "../redux/slices/adSlices/userAdsSlice";
import ListAdsHome from "./ListAdsHome";
import UserMenuCard from "./users/UserMenuCard";  


const Dashboard = () => {
  const dispatch = useDispatch();
  const { ads, loading, error, currentPage, totalPages } = useSelector(
    (state) => state.userAds
  );
  const { user } = useSelector((state) => state.auth); // Récupérer l'utilisateur connecté

  // Local state pour les filtres
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState("Toutes les dates");
  const [selectedDomain, setSelectedDomain] = useState("");

  // State pour la détection de la taille de l'écran
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Met à jour `isMobile` en cas de redimensionnement
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Charger les annonces de l'utilisateur connecté pour la page courante
  useEffect(() => {
    dispatch(fetchUserAds(currentPage)); // Charger les annonces en fonction de la page
  }, [dispatch, currentPage]);

  // Filtrage par date
  const filterByDate = (ad) => {
    const adDate = new Date(ad.created_at);
    const now = new Date();

    switch (selectedDateRange) {
      case "today": {
        const todayStart = new Date();
        todayStart.setHours(0, 0, 0, 0);
        const todayEnd = new Date();
        todayEnd.setHours(23, 59, 59, 999);
        return adDate >= todayStart && adDate <= todayEnd;
      }
      case "Dernier 7 jours": {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(now.getDate() - 7);
        return adDate >= sevenDaysAgo;
      }
      case "Dernier 30 jours": {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(now.getDate() - 30);
        return adDate >= thirtyDaysAgo;
      }
      case "Cette année": {
        return adDate.getFullYear() === now.getFullYear();
      }
      default:
        return true; // Pas de filtrage
    }
  };

  // Ajouter manuellement l'utilisateur connecté comme auteur dans chaque annonce
  const enrichedAds = Array.isArray(ads)
    ? ads.map((ad) => ({
        ...ad,
        user: user, // Ajouter l'utilisateur connecté comme auteur
      }))
    : [];

  // Vérifiez si `ads` est un tableau avant d'utiliser `filter`.
  const filteredAds = enrichedAds.filter((ad) => {
    const matchesSearchTerm =
      ad.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ad.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDomain = selectedDomain
      ? ad.food_industry_field === selectedDomain
      : true;
    const matchesDate = filterByDate(ad);
    return matchesSearchTerm && matchesDomain && matchesDate;
  });

  // Méthode pour changer de page
  const handlePageChange = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    } else if (direction === "next" && currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const resetFilters = () => {
    window.location.reload(); // Recharger la page
  };
  
  
  return (
    <div
      className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito", fontWeight: "500", fontStyle: "normal"

      }}
    >
       <h3 className="mt-4 mb-3" style={{ color: "#003366" }}>
        Bienvenue, voici vos annonces sur Espace Negoce
      </h3>

      <div className="row justify-content-start">
        {loading && <p>Chargement des annonces...</p>}
        {error && <p className="text-danger">Erreur : {error}</p>}
        {filteredAds.length > 0 ? (
          <ListAdsHome
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            
            selectedDomain={selectedDomain}
            setSelectedDomain={setSelectedDomain}
            filteredAds={filteredAds}
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        ) : (
          !loading && (
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ height: "50vh" }}
            >
              <p>Aucune annonce correspondant à votre recherche n'a été trouvée.</p>
              <button
                className="btn btn-primary"
                onClick={resetFilters}
                style={{
                  borderRadius: "10px",
                  fontWeight: "bold",
                  backgroundColor: "#036",
                  border: "none",
                }}
              >
                Retour à la liste des annonces
              </button>
            </div>
          )
        )}
          <div className="col-lg-3 col-md-12 col-sm-12">
             <UserMenuCard />
          </div>
      </div>
    </div>
  );
};

export default Dashboard;
