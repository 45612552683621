import React, { useEffect, useState } from "react";

const Contact = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    telephone: "",
    objet: "",
    message: "",
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ✅ Gérer la soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();
    const { nom, prenom, telephone, objet, message } = formData;

    if (nom && prenom && telephone && objet && message) {
      alert("Merci ! Votre message a bien été envoyé.");
      setFormData({ nom: "", prenom: "", telephone: "", objet: "", message: "" }); // Réinitialisation
    } else {
      alert("Veuillez remplir tous les champs avant d'envoyer.");
    }
  };

  // ✅ Gérer le changement des champs
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito",
        fontWeight: "500",
        fontStyle: "normal",
        padding: "30px",
      }}
    >
      <div className="row">
        
        {/* ✅ Colonne principale (col-9) avec une Card */}
        <div className="col-lg-12 col-12 d-flex justify-content-center align-items-center text-center" style={{ height: "80vh" }}>
          <div className="card p-4 shadow" style={{ width: "100%", maxWidth: "800px" }}> 
            <div className="card-body">
              <h3 className="card-title mb-3">Une question ? Une demande d'information ?</h3>
              <p className="card-text text-muted">
                Remplissez le formulaire ci-dessous et nous vous répondrons dans les plus brefs délais.
              </p>

              {/* ✅ Formulaire de contact */}
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control input-custom"
                        name="nom"
                        placeholder="Nom"
                        value={formData.nom}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control input-custom"
                        name="prenom"
                        placeholder="Prénom"
                        value={formData.prenom}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mb-3">
                  <input
                    type="tel"
                    className="form-control input-custom"
                    name="telephone"
                    placeholder="Téléphone"
                    value={formData.telephone}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control input-custom"
                    name="objet"
                    placeholder="Objet de votre message"
                    value={formData.objet}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group mb-3">
                  <textarea
                    className="form-control "
                    name="message"
                    rows="4"
                    placeholder="Votre message..."
                    value={formData.message}
                    onChange={handleChange}
                    required
                    style={{ resize: "none" }}
                  />
                </div>

                <button type="submit" className="btn btn-primary mt-3 w-100">
                  Envoyer
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* ✅ Styles CSS */}
      <style>
        {`
          .input-custom {
            font-size: 16px;
            color: #003366;
            height: 45px;
            border: 2px solid #003366;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
          }
          .input-custom:focus {
            border-color: #003366;
            box-shadow: 0px 0px 8px rgba(0, 47, 108, 0.5);
            outline: none;
          }
        `}
      </style>

    </div>
  );
};

export default Contact;
