import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import UserMenuCard from "../users/UserMenuCard"; // ✅ Importation du composant UserMenuCard

const NetworkFriend = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate(); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito",
        fontWeight: "500",
        fontStyle: "normal",
        padding: "30px",
      }}
    >
      <div className="row">       

        {/* Colonne principale (col-lg-9 sur desktop, col-12 sur mobile) */}
        <div className="col-lg-9 col-12 d-flex justify-content-center align-items-center text-center" style={{ height: "80vh" }}>
          <div>
            <h3 className="mb-3">Vous n'avez aucun contact pour le moment.</h3>
            <button 
              className="btn btn-primary px-4 py-2"
              onClick={() => navigate("/networking")} 
              style={{ background: "#002f6c", border: "none" }}
            >
              Créer votre réseau de contact
            </button>
          </div>
        </div>


        <div className="col-lg-3 col-12 mb-3">
          <UserMenuCard />
        </div>

      </div>
    </div>
  );
};

export default NetworkFriend;
