import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { ENDPOINTS } from "../../../utils/api";

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (userData, { rejectWithValue }) => {
    try {
      // 1️⃣ Appel obligatoire à `/sanctum/csrf-cookie` avant la requête POST
      await api.get("/sanctum/csrf-cookie", { withCredentials: true });

      // 2️⃣ Envoi de la requête d'inscription avec les cookies activés
      const response = await api.post(ENDPOINTS.REGISTER, userData, { withCredentials: true });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
