import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const MinimalLayout = ({ children }) => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>

    <style>
        {`
         body {
           
         }
        `}
    </style>
      {/* Navbar */}
      <Navbar />

      {/* Content Area */}
      <div
        className="flex-grow-1 d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: '#f0f2f5', 
         
        }}
      >
        <main
          className="flex-grow-1"
         
        >
          {children}
        </main>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default MinimalLayout;
