import React from "react";
import PropTypes from "prop-types";

const Button = ({
  label,
  onClick,
  type = "button",
  isLoading = false,
  disabled = false,
  icon = null,
  iconPosition = "left", // Position de l'icône : 'left' ou 'right'
  className = "",
  ...props
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`btn ${className} ${disabled || isLoading ? "disabled" : ""}`}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      ) : (
        <span className="d-flex align-items-center justify-content-center">
          {icon && iconPosition === "left" && (
            <span className="me-2">{icon}</span>
          )}
          {label}
          {icon && iconPosition === "right" && (
            <span className="ms-2">{icon}</span>
          )}
        </span>
      )}
    </button>
  );
};

// Définition des types de props
Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  className: PropTypes.string,
};

export default Button;
