import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import UserMenuCard from "./UserMenuCard"; // ✅ Importation de UserMenuCard

const UserSelection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate(); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container mt-4"
    style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito",
        fontWeight: "500",
        fontStyle: "normal",
        padding: "30px",
      }}
    >
      <div className="row">
        
        <div className="col-lg-9 col-12 d-flex justify-content-center align-items-center text-center" style={{ height: "80vh" }}>
          <div>
            <h3 className="mb-3">Les 100 dernières annonces que vous avez sélectionnées</h3>
            <p className="text-muted">
              Vous n'avez actuellement aucune annonce en sélection.<br />
              Pour en sélectionner, il vous suffit de cliquer sur 
              <strong> "Garder en sélection"</strong> sur la page décrivant chaque annonce.
            </p>
          </div>
        </div>

         <div className="col-lg-3 col-12 mb-3">
          <UserMenuCard />
        </div>

      </div>
    </div>
  );
};

export default UserSelection;
