import React from "react";
import { Link, useLocation } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../../styles/UserMenuCard.css";

const UserMenuCard = () => {
  const location = useLocation(); // ✅ Récupérer l'URL actuelle
  const unreadMessages = 5; // ✅ Nombre de messages non lus (statique pour l'instant)

  const menuItems = [
    { name: "Mes annonces", icon: "bi-megaphone", path: "/dashboard" },
    { name: "Mes contacts", icon: "bi-people", path: "/networking/friend" },
    { name: "Messagerie", icon: "bi-envelope", path: "/messenger", showBadge: true },
    { name: "Modifier votre profil", icon: "bi-pencil", path: "/user/profile" },
    { name: "Informations Officielles", icon: "bi-info-circle", path: "/user/official/infos" },
    { name: "Alertes/email", icon: "bi-bell", path: "/alert/email" },
    { name: "Votre sélection", icon: "bi-bookmark", path: "/user/selection" },
    { name: "Témoigner", icon: "bi-chat-left-quote", path: "/user/testimonials" },
    { name: "Changer mot de passe", icon: "bi-key", path: "/user/change/email" },
    { name: "Désinscription", icon: "bi-trash", path: "/user/unsubscribe" },
  ];

  return (
    <div
      style={{
        width: "266px",
        border: "1px solid #ddd",
        borderRadius: "10px",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        padding: "10px",
        position: "relative",
      }}
      className="menu-card"
    >
      <h4 style={{ textAlign: "center", marginBottom: "15px", color: "#003366" }}>
        Espace client
      </h4>
      <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
        {menuItems.map((item, index) => {
          const isActive = location.pathname === item.path; // ✅ Vérifie si l'élément est actif
          return (
            <li
              key={index}
              style={{
                padding: "8px",
                borderBottom: "1px solid #eee",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
                position: "relative",
                backgroundColor: isActive ? "#002f6c" : "transparent", // ✅ Couleur active
              }}
              onMouseEnter={(e) => {
                if (!isActive) e.currentTarget.style.backgroundColor = "#f0f0f0"; // ✅ Couleur survol
              }}
              onMouseLeave={(e) => {
                if (!isActive) e.currentTarget.style.backgroundColor = "transparent"; // ✅ Restauration
              }}
            >
              <Link
                to={item.path}
                style={{
                  textDecoration: "none",
                  color: isActive ? "#fff" : "#036", 
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                  transition: "color 0.3s ease",
                  position: "relative",
                }}
              >
                <i className={`bi ${item.icon}`} style={{ marginRight: "10px", fontSize: "18px" }}></i>
                {item.name}

                {/* ✅ Afficher le badge rouge uniquement pour la Messagerie */}
                {item.showBadge && unreadMessages > 0 && (
                  <span
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "bold",
                      borderRadius: "50%",
                      padding: "4px 8px",
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      minWidth: "20px",
                      textAlign: "center",
                    }}
                  >
                    {unreadMessages}
                  </span>
                )}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default UserMenuCard;
