import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createAd, resetCreateAdState } from '../../redux/slices/adSlices/createAdSlice';
import FormAds from '../../components/FormAds';
import { AGRO_DOMAINS } from '../../utils/Constants';

const CreateAdPage = () => {
  const [formData, setFormData] = useState({
    need_type: '',
    food_industry_field: '',
    title: '',
    description: '',
    quantity: '',
    price: '',
    brand: '',
    restrictions: '',
    shipping_conditions: '',
    transaction_details: '',
    media: [],
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, data } = useSelector((state) => state.createAd || {});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newFiles = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setFormData((prev) => ({
      ...prev,
      media: [...prev.media, ...newFiles],
    }));
  };

  const handleRemoveFile = (index) => {
    setFormData((prev) => {
      const updatedMedia = [...prev.media];
      updatedMedia.splice(index, 1);
      return { ...prev, media: updatedMedia };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedData = {
      ...formData,
      media: formData.media.map((item) => item.file),
    };

    dispatch(createAd(formattedData));
  };

  React.useEffect(() => {
    if (data && data.message) {
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    }

    return () => {
      dispatch(resetCreateAdState());
    };
  }, [data, dispatch, navigate]);

  // Fonction pour afficher un aperçu
  const renderPreview = () => (
    <div className="card p-3"  >
      <div className="d-flex align-items-center mb-3">
        <img
          src="https://via.placeholder.com/50"
          alt="Profil"
          className="rounded-circle me-2"
          style={{ width: '50px', height: '50px' }}
        />
        <div>
          <h6 className="mb-0">Aperçu des détails de votre annonce</h6>
          <small>En cours de création...</small>
        </div>
      </div>

      {/* Image principale ou placeholder */}
      {formData.media.length > 0 ? (
        <img
          src={formData.media[0].preview}
          alt="Preview"
          className="w-100 rounded"
          style={{ maxHeight: '200px', objectFit: 'cover' }}
        />
      ) : (
        <div
          className="w-100 rounded d-flex align-items-center justify-content-center"
          style={{
            maxHeight: '200px',
            height: '200px',
            backgroundColor: '#003366', 
            color: '#fff',
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Aucune image téléchargée
        </div>
      )}

      {/* Titre */}
      <h5 className="mt-3 " style={{ fontWeight: "bold" }}>{formData.title || 'Aucun titre spécifié'}</h5>

      {/* Informations */}
      <div className="row">
        
         <div className="col-6">
          <p>
            <strong>{formData.need_type || 'Aucun besoin'}</strong> 
          </p>
        </div>
        <div className="col-6">
          <p>
            <strong>{formData.food_industry_field || 'Aucune rubrique spécifiée'}</strong> 
          </p>
        </div>
        {/* Description (pleine largeur) */}
        <div className="col-12">
          <p>
            <strong>{formData.description || 'Aucune description'}</strong> 
          </p>
        </div>



        {/* Première colonne */}
        <div className="col-6">
         
          <p>
            <strong>Quantité :</strong> {formData.quantity || 'Non spécifié'}
          </p>
          <p>
            <strong>Marque :</strong> {formData.brand || 'Non spécifiée'}
          </p>
          <p>
            <strong>Restrictions :</strong> {formData.restrictions || 'Non spécifiées'}
          </p>
        </div>

        {/* Deuxième colonne */}
        <div className="col-6">
          
          <p>
            <strong>{formData.price || 'Prix non spécifié'} </strong> 
          </p>

          <p>
            <strong>{formData.shipping_conditions || 'Aucune condition spécifiée'}</strong> 
          </p>
         
        </div>

        <div className="col-12">
          <p>
            <strong>{formData.transaction_details || 'Aucune précision sur la transaction'}</strong> 
          </p>
        </div>

      </div>

      {/* <div className="d-flex justify-content-between mt-3">
        <button className="btn btn-primary btn-sm">En savoir plus</button>
        <button className="btn btn-secondary btn-sm">Envoyer Message</button>
      </div> */}

    </div>
  );

  return (
    <div className="container" style={{ maxWidth: '100%', marginLeft: '230px', paddingTop: "50px"}}>
      <div className="row">
        {/* Aperçu dans col-lg-5 */}
        <div className="col-lg-5">
          {renderPreview()}
        </div>

        {/* Formulaire dans col-lg-7 */}
        <div className="col-lg-7">
          <FormAds
            formData={formData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
            handleRemoveFile={handleRemoveFile}
            handleSubmit={handleSubmit}
            loading={loading}
            error={error}
            data={data}
            AGRO_DOMAINS={AGRO_DOMAINS}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateAdPage;
