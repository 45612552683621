import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL } from '../../../utils/api';

// ✅ Fonction pour créer une annonce
export const createAd = createAsyncThunk('ads/createAd', async (adData, thunkAPI) => {
  try {
    const token = sessionStorage.getItem('token'); // ✅ Récupération correcte du token
    console.log("Token récupéré :", token); 

    if (!token) {
      return thunkAPI.rejectWithValue("Aucun token trouvé. L'utilisateur doit être authentifié.");
    }

    // ✅ 1️⃣ Récupérer le cookie CSRF AVANT la requête (OBLIGATOIRE avec Sanctum)
    await axios.get(`${API_BASE_URL}/sanctum/csrf-cookie`, { withCredentials: true });

    const formData = new FormData();
    Object.keys(adData).forEach((key) => {
      if (key === 'media') {
        adData.media.forEach((file) => formData.append('media[]', file));
      } else {
        formData.append(key, adData[key]);
      }
    });

    // ✅ 2️⃣ Envoyer la requête avec le token d'authentification
    const response = await axios.post(
      `${API_BASE_URL}/ads`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true, // ✅ Obligatoire pour Sanctum
      }
    );

    console.log('Réponse API réussie :', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur API :', error.response);
    return thunkAPI.rejectWithValue(
      error.response?.data || 'Une erreur inconnue est survenue.'
    );
  }
});

// ✅ Slice Redux pour la gestion de la création d'annonces
const createAdSlice = createSlice({
  name: 'createAd',
  initialState: {
    loading: false, 
    success: false, 
    error: null,    
    data: null,     
  },
  reducers: {
    resetCreateAdState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAd.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(createAd.fulfilled, (state, action) => {
        console.log('Action fulfilled :', action.payload);
        state.loading = false;
        state.success = true;
        state.data = action.payload;
      })
      .addCase(createAd.rejected, (state, action) => {
        console.error('Action rejetée :', action.payload);
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export const { resetCreateAdState } = createAdSlice.actions;
export default createAdSlice.reducer;
