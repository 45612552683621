import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import UserMenuCard from "./UserMenuCard"; // ✅ Importation de UserMenuCard

const UserTemoignage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [temoignage, setTemoignage] = useState(""); // ✅ Stocke le témoignage
  const navigate = useNavigate(); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fonction pour envoyer le témoignage
  const handleSubmit = (e) => {
    e.preventDefault();
    if (temoignage.trim()) {
      alert("Merci pour votre témoignage !");
      setTemoignage(""); // Réinitialisation du champ après l'envoi
    } else {
      alert("Veuillez écrire un témoignage avant d'envoyer.");
    }
  };

  return (
    <div className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito",
        fontWeight: "500",
        fontStyle: "normal",
        padding: "30px",
      }}
    >
      <div className="row">
        
        {/* Colonne principale (col-9) avec une Card */}
        <div className="col-lg-9 col-12 d-flex justify-content-center align-items-center text-center" style={{ height: "80vh" }}>
          <div className="card p-4 shadow" style={{ width: "100%", maxWidth: "600px" }}> {/* ✅ Ajout d'une Card */}
            <div className="card-body">
              <h3 className="card-title mb-3">Apportez votre témoignage</h3>
              <p className="card-text text-muted">
                Indiquez ci-dessous votre témoignage (quelques lignes suffisent).<br />
                Si vous êtes satisfait des affaires que nous vous avons aidé à réaliser, n'hésitez pas à le dire... Merci !
              </p>

              {/* Formulaire de témoignage */}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Écrivez votre témoignage ici..."
                    value={temoignage}
                    onChange={(e) => setTemoignage(e.target.value)}
                    style={{ resize: "none", fontSize: "16px", color: "#003366" }}
                  />
                </div>

                <button type="submit" className="btn btn-primary mt-3 w-100">
                  Envoyer
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Colonne UserMenuCard (col-3) */}
        <div className="col-lg-3 col-12 mb-3">
          <UserMenuCard />
        </div>

      </div>
    </div>
  );
};

export default UserTemoignage;
