import React, { useEffect } from "react";

const Banner = () => {
  useEffect(() => {
    const carousel = document.querySelector("#carouselExample");

    // Fonction pour stopper l'autoplay au survol
    const pauseCarousel = () => carousel?.classList.add("paused");
    
    // Fonction pour reprendre l'autoplay lorsque la souris quitte
    const resumeCarousel = () => carousel?.classList.remove("paused");

    // Ajouter les événements
    carousel?.addEventListener("mouseenter", pauseCarousel);
    carousel?.addEventListener("mouseleave", resumeCarousel);

    return () => {
      carousel?.removeEventListener("mouseenter", pauseCarousel);
      carousel?.removeEventListener("mouseleave", resumeCarousel);
    };
  }, []);

  return (
    <div className="container-fluid p-0">
      <div id="carouselExample" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="3000">
        <div className="carousel-inner">
          {/* Liste des slides */}
          {[
            {
              img: "images/banner.png",
              text: "Acheter, vendre, réseauter, prospérer…",
              subtitle: "C’est ici que ça se passe !",
            },
            {
              img: "images/banner6.png",
              text: "Du négoce à la logistique",
              subtitle: "Gagnez du temps et optimisez vos échanges.",
            },
            {
              img: "images/banner7.png",
              text: "Du végétal à l’énergie",
              subtitle: "La filière verte se développe sur Espace Négoce.",
            },
            {
              img: "images/banner8.png",
              text: "Des Engrais, additifs et biocarburants",
              subtitle: "Maîtrisez toute la chaîne de valeur.",
            },
            {
              img: "images/banner1.png",
              text: "Espace Négoce",
              subtitle: "Nous vous connectons à l’offre et la demande en Afrique.",
            },
            {
              img: "images/banner2.png",
              text: "Espace Négoce",
              subtitle: "Haricots, Blé, Maïs, Riz : un marché céréalier sans frontières.",
            },
            {
              img: "images/banner4.png",
              text: "Espace Négoce",
              subtitle: "Découvrez un réseau international pour vos affaires.",
            },
          ].map((slide, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`} data-bs-interval="3000">
              <img
                src={slide.img}
                className="d-block w-100 img-fluid"
                alt={`Bannière ${index + 1}`}
                style={{ filter: "brightness(60%)", borderRadius: "10px", height: "400px", objectFit: "cover" }}
              />
              <div className="carousel-caption custom-caption">
                <h2>
                  {slide.text.includes("Espace Négoce") ? (
                    <>
                      <span style={{ color: "#00cc44", fontWeight: "bold" }}>{slide.text}</span>
                    </>
                  ) : (
                    <>
                      {slide.text.split(" ").map((word, i) =>
                        i === 1 ? <span key={i} style={{ color: "#00cc44", fontWeight: "bold" }}> {word} </span> : ` ${word}`
                      )}
                    </>
                  )}
                  <br /> {slide.subtitle}
                </h2>
              </div>
            </div>
          ))}
        </div>

        {/* Boutons de navigation */}
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  );
};

export default Banner;
